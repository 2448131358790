.AppHeaderSubmenu {
  position: absolute;
  top: 60px;
  right: -100px;

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    border-radius: 32px;
    z-index: 11;
  }

  &__List {
    width: 270px;
    height: 100%;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    padding: 12px !important;
    border-radius: 30px;
    z-index: 12;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__Item {
    padding: 12px !important;
    a {
      padding: 0 !important;
    }
    &--Active{
      a{
        color: var(--color-black);
      }
    }
  }
}
