.AppError {
  padding: 96px 0;

  @media (max-width: 1680px) {
    padding: 85px 0;
  }

  @media (max-width: 1440px) {
    padding: 80px 0;
  }

  @media (max-width: 1366px) {
    padding: 75px 0;
  }

  @media (max-width: 1280px) {
    padding: 70px 0;
  }

  @media (max-width: 1024px) {
    padding: 65px 0;
  }

  @media (max-width: 768px) {
    padding: 60px 0;
  }

  &__Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    margin: 0 auto;
    width: 700px;

    @media (max-width: 1440px) {
      width: 600px;
    }

    @media (max-width: 1366px) {
      width: 500px;
    }

    @media (max-width: 1280px) {
      width: 400px;
    }

    @media (max-width: 1024px) {
      width: 300px;
    }

    @media (max-width: 768px) {
      width: 280px;
    }

    &__V1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      > span {
        font-family: var(--font-base);
        font-style: normal;
        font-weight: var(--fw-sb);
        font-size: var(--fs-d-xs);
        line-height: 1.3;
        color: var(--color-black);

        @media (max-width: 1366px) {
          font-size: var(--fs-d-xs);
        }
        @media (max-width: 1280px) {
          font-size: var(--fs-t-xl);
        }
        @media (max-width: 1024px) {
          font-size: var(--fs-t-lg);
        }
      }

      &__Image {
        width: 100%;
        height: 324px;

        @media (max-width: 1440px) {
          height: 270px;
        }
        @media (max-width: 1366px) {
          height: 230px;
        }
        @media (max-width: 1280px) {
          height: 180px;
        }
        @media (max-width: 1024px) {
          height: 140px;
        }
        @media (max-width: 768px) {
          height: 120px;
        }
      }
    }

    &__V2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      &__Image {
        width: 100%;
        height: 628px;

        @media (max-width: 1440px) {
          height: 500px;
        }
        @media (max-width: 1366px) {
          height: 420px;
        }
        @media (max-width: 1280px) {
          height: 330px;
        }
        @media (max-width: 1024px) {
          height: 260px;
        }
        @media (max-width: 768px) {
          height: 240px;
        }
      }
    }

    &__Button {
      text-align: end;

      @media (max-width: 1024px) {
        text-align: center;
      }
    }
  }
}
