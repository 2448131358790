.AppHeader__Burger__Menu {
  display: none;
  position: absolute;
  top: 34px;
  right: 30px;
  z-index: 1000;

  @media (max-width: 1024px) {
    display: block;
  }

  .AppHeader__Burger__Menu__Wrapper {
    width: 30px;
    height: 24px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: var(--color-black);
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }

      &:nth-child(4) {
        top: 20px;
      }
    }
  }

  .Open {
    span {
      &:nth-child(1),
      &:nth-child(4) {
        opacity: 0;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

.AppHeader__Resp {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-25);
  opacity: 0;
  z-index: 99;

  transition: 0.3s ease-in-out;

  .AppHeader__Resp__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

    &__Head {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      //margin-bottom: 40px;
      width: 100%;
      border-bottom: 2px solid var(--color-black);
      border-top: 2px solid var(--color-black);

      &__LanguageSwitcher {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        button {
          min-height: 88px;
          background-color: var(--color-yellow);
          height: 100%;
          &:hover {
            background-color: var(--color-gray-25);
          }
        }
      }

      .AppHeader__Logo {
        padding: 30px 0;
        a {
          display: block;
        }
      }
    }

    &__Content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul {
        list-style-type: none;
        margin: 0 0 40px 0;
        padding: 0;

        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
          text-align: center;
          color: var(--color-gray-500);
          a {
            font-family: var(--font-base);
            font-style: normal;
            font-weight: var(--fw-b);
            font-size: var(--fs-d-xs);
            color: var(--color-gray-500);
          }
        }
      }

      &__Contact {
        a {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          min-height: 80px;
          padding: 30px 36px;

          font-family: var(--font-base);
          color: var(--color-white);
          font-weight: var(--fw-m);
          font-size: var(--fs-t-lg);

          background: var(--color-black);
          border-radius: 0;
          box-shadow: unset;
          border: 2px solid var(--color-black);
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--color-yellow);
            color: var(--color-black);
          }
        }
      }
    }
  }

  &.Visible {
    top: 0;
    opacity: 1;
  }
}

.AppHeaderResp__Item {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: var(--fw-b);
  font-size: var(--fs-d-xs);
}
.AppHeader__Resp__Active {
  & > span {
    color: var(--color-black);
  }
  & > a {
    color: var(--color-black);
  }
}

.AppHeaderResp__Icon {
  margin-left: 8px;
}

.AppHeaderResp__List {
  margin: 12px !important;
  gap: 12px !important;
}

.AppHeaderResp__ListItem {
  a {
    font-weight: var(--fw-m);
    font-size: var(--fs-t-lg) !important;
  }
  &--Active {
    a {
      color: var(--color-black) !important;
    }
  }
}
