.AppHeader {
  background-color: var(--color-gray-25);

  &__Image {
    width: 100%;
    height: 100%;
    min-height: 280px;
    max-height: 380px;
    overflow: hidden;

    @media (max-width: 1024px) {
      display: none;
    }

    span {
      display: block;
      width: 100%;
      height: auto;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &__Content {
    border-top: 2px solid var(--color-black);
    border-bottom: 2px solid var(--color-black);

    .AppHeader__Wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      @media (max-width: 1024px) {
        justify-content: center;
      }

      &__middle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;
        padding: 0 10px;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      &__right {
        display: flex;
        flex-direction: row;
        align-items: center;

        border-left: 2px solid var(--color-black);

        @media (max-width: 1024px) {
          display: none;
        }

        .AppHeader__LanguageSwitcher {
        }

        .AppHeader__Contact {
          a {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            min-height: 80px;
            padding: 30px 36px;

            font-family: var(--font-base);
            color: var(--color-white);
            font-weight: var(--fw-m);
            font-size: var(--fs-t-lg);

            background: var(--color-black);
            border-radius: 0;
            box-shadow: unset;
            border-top: none;
            border-left: 2px solid var(--color-black);
            border-right: 2px solid var(--color-black);
            border-bottom: none;
            transition: all 0.3s ease;

            &:hover {
              background-color: var(--color-yellow);
              color: var(--color-black);
            }
          }
        }
      }
    }
  }

  &__Logo {
    @media (max-width: 1024px) {
      padding: 30px 0;
    }
    a {
      display: block;
    }
  }
}
