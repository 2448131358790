.AppButton__Round__Wrapper {
  display: inline-flex;
  background-color: var(--color-black);
  border-radius: 30px;
  box-shadow: 0px 1px 2px rgba(var(--color-black-rgb-800), 0.05);
  &:hover {
    .AppButton {
      transform: translate(-5px, -5px);
    }
  }
  &:active {
    .AppButton {
      transform: translate(0, 0);
    }
  }
}

.AppButton {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 55px;
  padding: 16px 28px;
  background: var(--color-gray-25);
  border: 1px solid var(--color-black);
  border-radius: 30px;
  box-shadow: 0px 1px 2px rgba(var(--color-black-rgb-800), 0.05);
  transition: all 0.3s ease;
  span {
    font-family: var(--font-base);
    color: var(--color-black);
    font-weight: var(--fw-m);
    font-size: var(--fs-t-lg);
  }
}

.AppButton--CTA {
  height: 41px;
  background-color: var(--color-yellow);
  padding: 10px 28px;
  span {
    font-size: var(--fs-t-md);
  }
}

.AppButton__Square__Wrapper {
  display: inline-flex;
  background-color: var(--color-black);
  border-radius: 0;
  box-shadow: unset;
  height: 100%;
}

.AppButtonSquare {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  min-height: 80px;
  padding: 30px 36px;
  background: var(--color-black);
  border: 1px solid var(--color-black);
  border-radius: 0;
  box-shadow: unset;
  transition: all 0.3s ease;

  span {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: var(--fw-m);
    font-size: var(--fs-t-lg);
  }

  &:hover {
    background-color: var(--color-yellow);
    span {
      color: var(--color-black);
    }
  }
}

.AppButtonSquare--IsHeader {
  border-top: none;
  border-left: 2px solid var(--color-black);
  border-right: 2px solid var(--color-black);
  border-bottom: none;
}

.AppButtonSquare--Transparent {
  background-color: var(--color-white);
  border: none;
  span {
    color: var(--color-black);
  }
  &:hover {
    background-color: var(--color-yellow);
  }
}

.AppButtonSquare--Yellow {
  background-color: var(--color-yellow);
  span {
    color: var(--color-black);
  }
  &:hover {
    background-color: var(--color-white);
  }
}

.AppButtonRound--Yellow {
  background-color: var(--color-yellow);
  span {
    color: var(--color-black);
  }
  &:hover {
    background-color: var(--color-white);
  }
}
