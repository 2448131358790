.AppHeaderNav {
  margin: 0;
  padding: 0;

  &__Menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    gap: 32px;
  }

  li {
    margin: 0;
    padding: 30px 0;
    a:hover {
      color: var(--color-black);
    }
  }

  &__MenuItem {
    padding: 30px 0;
    font-family: var(--font-base);
    font-style: normal;
    font-weight: var(--fw-m);
    font-size: var(--fs-t-md);
    line-height: 1.3;
    color: var(--color-gray-500);
    opacity: 1;
    transition: 0.3s ease;
    cursor: pointer;
    & > a {
      padding: 30px 0;
      font-family: var(--font-base);
      font-style: normal;
      font-weight: var(--fw-m);
      font-size: var(--fs-t-md);
      line-height: 1.3;
      color: var(--color-gray-500);
      opacity: 1;
      transition: 0.3s ease;
    }
  }

  &__Active {
    & > span {
      color: var(--color-black);
    }
    & > a {
      color: var(--color-black) !important;
    }
  }

  &__Icon {
    margin-left: 8px;
    pointer-events: none;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
